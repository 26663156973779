@font-face {
  font-family: 'Horizon Regular';
  src: url('./assets/fonts/HorizonRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 16px; /* Slightly reduced base font size */
}

body {
  margin: 0;
  font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',

    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem; /* Equivalent to 16px */
  line-height: 1.5;
  letter-spacing: 0.01em;
}

.horizon-font {
  font-family: 'Inter' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos específicos para móvil cuando el menú está abierto */
@media (max-width: 768px) {
  body.menu-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .menu-open .navbar {
    background-color: #1e40af !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
  }

  .menu-open .mobile-menu {
    background-color: #1e40af;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding-top: 4rem;
    min-height: 100vh;
    overflow-y: auto;
  }

  .menu-open .banner-slider,
  .menu-open .banner-container {
    z-index: 1;
  }

  .menu-open .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1e40af;
    z-index: 998;
  }
}